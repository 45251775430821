import {
  all,
  takeEvery,
  put,
  call,
  fork,
  spawn,
  select,
  delay,
} from 'redux-saga/effects';

import appActions from '../app/actions';
import actions from './actions';
import api from '../../helpers/api';
import { ResponseGenerator } from '../types';
import router from 'next/router';

export const getListAluno = (state: any) => state.AlunosReducer.listAlunos;
export const getListCNHS = (state: any) => state.AlunosReducer.listCNHS;
export const getListFinanceiro = (state: any) => state.AlunosReducer.listFinanceiro;
export const getListFiles = (state: any) => state.AlunosReducer.listFiles;
export const getAluno = (state: any) => state.AlunosReducer.alunos;
export const getAlunoID = (state: any) => state.AlunosReducer.alunoID;

export function* insertAlunoRequest() {
  yield takeEvery(
    actions.INSERT_ALUNO_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let result: ResponseGenerator = yield call(api.insertAlunos, payload);
        yield put({
          type: actions.INSERT_ALUNO_SUCCESS,
          payload: result,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
        if (result.data.codigo && typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* patchAlunoRequest() {
  yield takeEvery(
    actions.PATCH_ALUNO_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        let result: ResponseGenerator;

        if (payload.contatos?.length) {
          result = yield call(api.patchAlunos, payload);
        } else if (payload.observacao || payload.observacao == null) {
          result = yield call(api.patchAlunosObservacao, payload);
        }

        yield put({ type: appActions.IS_LOADING, payload: false });

        if (typeof callback == 'function') {
          callback();
        }

        yield put({
          type: actions.PATCH_ALUNO_SUCCESS, // @ts-ignore
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* getListagemAlunosRequest() {
  yield takeEvery(
    actions.FETCH_LIST_ALUNOS_REQUEST,
    function* ({ payload, quantityError = 1 }: any) {
      try {
        yield put({ type: actions.SET_IS_LOADING_LIST, payload: true });
        const result: ResponseGenerator = yield call(api.fetchListAlunos, payload);
        yield put({
          type: actions.FETCH_LIST_ALUNOS_SUCCESS,
          payload: result,
        });

        yield put({ type: actions.SET_IS_LOADING_LIST, payload: false });
      } catch (error: any) {
        if (error?.erro?.extra?.timeOut && quantityError < 2) {
          yield delay(3000);

          yield put({
            type: actions.FETCH_LIST_ALUNOS_REQUEST,
            payload: payload,
            quantityError: quantityError + 1,
          });
        } else {
          yield put({ type: actions.SET_IS_LOADING_LIST, payload: false });
          yield put({
            type: appActions.HANDLE_ERRORS,
            payload: error,
          });
        }
      }
    }
  );
}

export function* getListagemFilesAlunosRequest() {
  yield takeEvery(actions.FETCH_LIST_FILES_REQUEST, function* ({ alunoID }: any) {
    try {
      const result: ResponseGenerator = yield call(api.getFilesAlunos, alunoID);
      yield put({
        type: appActions.IS_LOADING,
        payload: false,
      });

      yield put({
        type: actions.FETCH_LIST_FILES_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* getListagemFinanceiroAlunosRequest() {
  yield takeEvery(
    actions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
    function* ({ alunoID }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResponseGenerator = yield call(
          api.getFinanceiroAluno,
          alunoID
        );
        yield put({ type: appActions.IS_LOADING, payload: false });

        yield put({
          type: actions.FETCH_LIST_FINANCEIRO_ALUNO_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* fetchAlunoRequest() {
  yield takeEvery(actions.FETCH_ALUNO_REQUEST, function* ({ payload }: any) {
    try {
      const result: ResponseGenerator = yield call(api.fetchAluno, payload);
      yield put({
        type: actions.FETCH_ALUNO_SUCCESS,
        payload: result,
      });
    } catch (error) {
      router.replace('/alunos');
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* updateAlunoRequest() {
  yield takeEvery(
    actions.UPDATE_ALUNO_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResponseGenerator = yield call(api.updateAluno, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.UPDATE_ALUNO_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteAlunosRequest() {
  yield takeEvery(
    actions.DELETE_ALUNO_REQUEST,
    function* ({ payload, params }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.deleteAluno, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_ALUNO_SUCCESS,
          params: params,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* inactiveAlunosRequest() {
  yield takeEvery(
    actions.INACTIVE_ALUNO_REQUEST,
    function* ({ payload, status, params }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.inactiveAluno, payload, status);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INACTIVE_ALUNO_SUCCESS,
          params: params,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* getListagemCNHsAlunoRequest() {
  yield takeEvery(actions.FETCH_LIST_CNHS_REQUEST, function* ({ alunoID }: any) {
    try {
      const result: ResponseGenerator = yield call(api.getCnhs, alunoID);
      yield put({
        type: actions.FETCH_LIST_CNHS_SUCCESS,
        payload: {
          data: result.data,
          meta: result.meta,
        },
      });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* reopenProcessRequest() {
  yield takeEvery(
    actions.REABRIR_PROCESSO_ALUNO_REQUEST,
    function* ({ vendaID, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let result: ResponseGenerator = yield call(api.reopenProcess, vendaID);
        yield put({
          type: actions.REABRIR_PROCESSO_ALUNO_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertCNHRequest() {
  yield takeEvery(
    actions.INSERT_CNH_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let result: ResponseGenerator = yield call(api.insertCNH, payload);
        yield put({
          type: actions.INSERT_CNH_SUCCESS,
          payload: result,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* updateCNHRequest() {
  yield takeEvery(
    actions.UPDATE_CNH_REQUEST,
    function* ({ payload, idCNH, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResponseGenerator = yield call(api.updateCNH, payload, idCNH);
        yield put({
          type: actions.UPDATE_CNH_SUCCESS,
          payload: result,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteCNHRequest() {
  yield takeEvery(
    actions.DELETE_CNH_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResponseGenerator = yield call(api.deleteCNH, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.DELETE_CNH_SUCCESS,
          payload: result,
        });
        if (typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* insertRenachRequest() {
  yield takeEvery(
    actions.INSERT_RENACH_REQUEST,
    function* ({ payload, vendaID, callback, values }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let result: ResponseGenerator = yield call(
          api.insertRenach,
          payload,
          vendaID
        );
        yield put({
          type: actions.INSERT_RENACH_SUCCESS,
          payload: result,
          values,
        });
        callback();
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* payInstallmentsRequest() {
  yield takeEvery(
    actions.PAY_INSTALLMENTS_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResponseGenerator = yield call(api.payInstallments, payload);
        yield put({
          type: actions.PAY_INSTALLMENTS_SUCCESS,
          payload: result,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
        if (callback && typeof callback == 'function') {
          callback();
        }
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* dividerInstallmentsRequest() {
  yield takeEvery(
    actions.DIVIDER_INSTALLMENTS_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResponseGenerator = yield call(
          api.dividerInstallments,
          payload
        );
        yield put({
          type: actions.DIVIDER_INSTALLMENTS_SUCCESS,
          payload: result,
        });
        if (callback && typeof callback == 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* reopenInstallmentsRequest() {
  yield takeEvery(
    actions.REOPEN_INSTALLMENTS_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let result: ResponseGenerator;

        if (payload.length > 1) {
          result = yield call(api.reopenInstallments, payload[0], payload[1]);
        } else {
          result = yield call(api.reopenInstallments, payload, 1);
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
        callback();
        yield put({
          type: actions.REOPEN_INSTALLMENTS_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* reopenInstallmentsStatementRequest() {
  yield takeEvery(
    actions.REOPEN_INSTALLMENTS_STATEMENT_REQUEST,
    function* ({ payload, callback }: any) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        let result: ResponseGenerator;

        if (payload.length > 1) {
          result = yield call(
            api.reopenInstallmentsStatement,
            payload[0],
            payload[1]
          );
        } else {
          result = yield call(api.reopenInstallmentsStatement, payload, 1);
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
        callback();
        yield put({
          type: actions.REOPEN_INSTALLMENTS_STATEMENT_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({
          type: appActions.HANDLE_ERRORS,
          payload: error,
        });
      }
    }
  );
}

export function* deleteFileRequest() {
  yield takeEvery(actions.DELETE_FILE_REQUEST, function* ({ payload }: any) {
    try {
      yield put({ type: appActions.IS_LOADING, payload: true });
      yield call(api.deleteFile, payload);
      yield put({
        type: actions.DELETE_FILE_SUCCESS,
      });
      yield put({ type: appActions.IS_LOADING, payload: false });
    } catch (error) {
      yield put({
        type: appActions.HANDLE_ERRORS,
        payload: error,
      });
    }
  });
}

export function* getAlunoSuccess() {
  yield takeEvery(actions.FETCH_LIST_ALUNOS_SUCCESS, function* ({ payload }: any) {
    yield put({ type: appActions.IS_LOADING, payload: false });
  });
}

export function* insertAlunoSuccess() {
  yield takeEvery(actions.INSERT_ALUNO_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Aluno cadastrado com sucesso!' },
    });
    yield put({
      type: actions.FETCH_ALUNO_SUCCESS,
      payload: payload,
    });
  });
}

export function* insertCNHSuccess() {
  yield takeEvery(actions.INSERT_CNH_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'CNH cadastrada com sucesso!' },
    });
    yield put({
      type: actions.FETCH_LIST_CNHS_REQUEST,
      alunoID: payload.data.pessoa,
    });
  });
}

export function* payInstallmentsSuccess() {
  yield takeEvery(actions.PAY_INSTALLMENTS_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Pagamento efetuado com sucesso!' },
    });
    let alunoID: Generator = yield select(getAlunoID);
    yield put({
      type: actions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
      alunoID: alunoID,
    });
  });
}

export function* dividerInstallmentsSuccess() {
  yield takeEvery(actions.DIVIDER_INSTALLMENTS_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Parcela alterada com sucesso!' },
    });
    let alunoID: Generator = yield select(getAlunoID);
    yield put({
      type: actions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
      alunoID: alunoID,
    });
  });
}

export function* reopenInstallmentsSuccess() {
  yield takeEvery(actions.REOPEN_INSTALLMENTS_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Parcela reaberta com sucesso!' },
    });
    //So volta o codigo da empresa qnd reabre a parcela apartir do financeiro
    let alunoID: Generator = yield select(getAlunoID);
    if (Boolean(payload && payload.data && payload.data.codigo_empresa && alunoID)) {
      yield put({
        type: actions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
        alunoID: alunoID,
      });
    }
  });
}
export function* reopenInstallmentsStatementSuccess() {
  yield takeEvery(
    actions.REOPEN_INSTALLMENTS_STATEMENT_SUCCESS,
    function* ({ payload }: any) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Reaberta com sucesso!' },
      });
    }
  );
}

export function* reopenProcessSuccess() {
  yield takeEvery(
    actions.REABRIR_PROCESSO_ALUNO_SUCCESS,
    function* ({ payload }: any) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: { activated: true, message: 'Processo reaberto com sucesso!' },
      });
      yield put({
        type: actions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
        alunoID: payload.data.pessoa,
      });
    }
  );
}

export function* insertRenachSuccess() {
  yield takeEvery(actions.INSERT_RENACH_SUCCESS, function* ({ values }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true },
    });
    if (values) {
      yield put({
        type: actions.FETCH_LIST_ALUNOS_REQUEST,
        payload: { ...values },
      });
    }
  });
}

export function* updateCNHSuccess() {
  yield takeEvery(actions.UPDATE_CNH_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'CNH atualizada com sucesso!' },
    });
    yield put({
      type: actions.FETCH_LIST_CNHS_REQUEST,
      alunoID: payload.data.pessoa,
    });
  });
}

export function* patchAlunoSuccess() {
  yield takeEvery(actions.PATCH_ALUNO_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Aluno atualizado com sucesso!' },
    });
  });
}
export function* updateAlunosSuccess() {
  yield takeEvery(actions.UPDATE_ALUNO_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        message: 'Dados do aluno atualizados com sucesso!',
      },
    });
  });
}

export function* deleteAlunosSuccess() {
  yield takeEvery(actions.DELETE_ALUNO_SUCCESS, function* ({ params }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Aluno excluído com sucesso!' },
    });

    yield put({
      type: actions.FETCH_LIST_ALUNOS_REQUEST,
      payload: { ...params, page: 1 },
    });
  });
}

export function* inactiveAlunosSuccess() {
  yield takeEvery(actions.INACTIVE_ALUNO_SUCCESS, function* ({ params }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Aluno inativado com sucesso!' },
    });
    yield put({
      type: actions.FETCH_LIST_ALUNOS_REQUEST,
      payload: { ...params, page: 1 },
    });
  });
}

export function* deleteCNHSuccess() {
  yield takeEvery(actions.DELETE_CNH_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'CNH excluído com sucesso!' },
    });
    yield put({
      type: actions.FETCH_LIST_CNHS_REQUEST,
      alunoID: payload.data.codigo,
    });
  });
}

export function* deleteFileSuccess() {
  yield takeEvery(actions.DELETE_FILE_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: { activated: true, message: 'Documento excluído com sucesso!' },
    });
    yield put({
      type: actions.FETCH_LIST_FILES_REQUEST,
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(getListagemAlunosRequest),
    spawn(getListagemFinanceiroAlunosRequest),
    spawn(getListagemFilesAlunosRequest),
    spawn(getListagemCNHsAlunoRequest),
    spawn(insertAlunoRequest),
    spawn(updateAlunoRequest),
    spawn(deleteFileRequest),
    spawn(fetchAlunoRequest),
    spawn(deleteAlunosRequest),
    spawn(patchAlunoRequest),
    spawn(dividerInstallmentsRequest),
    spawn(inactiveAlunosRequest),
    spawn(insertCNHRequest),
    spawn(insertRenachRequest),
    spawn(updateCNHRequest),
    spawn(deleteCNHRequest),
    spawn(reopenProcessRequest),
    spawn(payInstallmentsRequest),
    spawn(reopenInstallmentsRequest),
    spawn(reopenInstallmentsStatementRequest),
    fork(payInstallmentsSuccess),
    fork(reopenInstallmentsSuccess),
    fork(deleteAlunosSuccess),
    fork(getAlunoSuccess),
    fork(insertAlunoSuccess),
    fork(updateAlunosSuccess),
    fork(inactiveAlunosSuccess),
    fork(patchAlunoSuccess),
    fork(insertCNHSuccess),
    fork(updateCNHSuccess),
    fork(deleteCNHSuccess),
    fork(insertRenachSuccess),
    fork(deleteFileSuccess),
    fork(reopenProcessSuccess),
    fork(dividerInstallmentsSuccess),
    fork(reopenInstallmentsStatementSuccess),
  ]);
}
