const actions = {
  INSERT_ALUNO_REQUEST: 'INSERT_ALUNO_REQUEST',
  INSERT_ALUNO_SUCCESS: 'INSERT_ALUNO_SUCCESS',
  PATCH_ALUNO_REQUEST: 'PATCH_ALUNO_REQUEST',
  PATCH_ALUNO_SUCCESS: 'PATCH_ALUNO_SUCCESS',
  SET_IS_LOADING_LIST: 'SET_IS_LOADING_LIST',
  FETCH_LIST_ALUNOS_REQUEST: 'FETCH_LIST_ALUNOS_REQUEST',
  FETCH_LIST_ALUNOS_SUCCESS: 'FETCH_LIST_ALUNOS_SUCCESS',
  FETCH_ALUNO_SUCCESS: 'FETCH_ALUNO_SUCCESS',
  FETCH_ALUNO_REQUEST: 'FETCH_ALUNO_REQUEST',
  DELETE_ALUNO_REQUEST: 'DELETE_ALUNO_REQUEST',
  UPDATE_ALUNO_REQUEST: 'UPDATE_ALUNO_REQUEST',
  UPDATE_ALUNO_SUCCESS: 'UPDATE_ALUNO_SUCCESS',
  DELETE_ALUNO_SUCCESS: 'DELETE_ALUNO_SUCCESS',
  INACTIVE_ALUNO_REQUEST: 'INACTIVE_ALUNO_REQUEST',
  INACTIVE_ALUNO_SUCCESS: 'INACTIVE_ALUNO_SUCCESS',
  SET_ALUNO_ID: 'SET_ALUNO_ID',
  FETCH_LIST_CNHS_REQUEST: 'FETCH_LIST_CNHS_REQUEST',
  FETCH_LIST_CNHS_SUCCESS: 'FETCH_LIST_CNHS_SUCCESS',
  INSERT_CNH_REQUEST: 'INSERT_CNH_REQUEST',
  INSERT_CNH_SUCCESS: 'INSERT_CNH_SUCCESS',
  UPDATE_CNH_REQUEST: 'UPDATE_CNH_REQUEST',
  UPDATE_CNH_SUCCESS: 'UPDATE_CNH_SUCCESS',
  DELETE_CNH_REQUEST: 'DELETE_CNH_REQUEST',
  DELETE_CNH_SUCCESS: 'DELETE_CNH_SUCCESS',
  INSERT_RENACH_REQUEST: 'INSERT_RENACH_REQUEST',
  INSERT_RENACH_SUCCESS: 'INSERT_RENACH_SUCCESS',
  PAY_INSTALLMENTS_REQUEST: 'PAY_INSTALLMENTS_REQUEST',
  PAY_INSTALLMENTS_SUCCESS: 'PAY_INSTALLMENTS_SUCCESS',
  DIVIDER_INSTALLMENTS_REQUEST: 'DIVIDER_INSTALLMENTS_REQUEST',
  DIVIDER_INSTALLMENTS_SUCCESS: 'DIVIDER_INSTALLMENTS_SUCCESS',
  REOPEN_INSTALLMENTS_REQUEST: 'REOPEN_INSTALLMENTS_REQUEST',
  REOPEN_INSTALLMENTS_SUCCESS: 'REOPEN_INSTALLMENTS_SUCCESS',
  REOPEN_INSTALLMENTS_STATEMENT_REQUEST: 'REOPEN_INSTALLMENTS_STATEMENT_REQUEST',
  REOPEN_INSTALLMENTS_STATEMENT_SUCCESS: 'REOPEN_INSTALLMENTS_STATEMENT_SUCCESS',
  COMPLETE_PROCESSO_ALUNO_REQUEST: 'COMPLETE_PROCESSO_ALUNO_REQUEST',
  COMPLETE_PROCESSO_ALUNO_SUCCESS: 'COMPLETE_PROCESSO_ALUNO_SUCCESS',
  REABRIR_PROCESSO_ALUNO_REQUEST: 'REABRIR_PROCESSO_ALUNO_REQUEST',
  REABRIR_PROCESSO_ALUNO_SUCCESS: 'REABRIR_PROCESSO_ALUNO_SUCCESS',
  FETCH_LIST_FINANCEIRO_ALUNO_REQUEST: 'FETCH_LIST_FINANCEIRO_ALUNO_REQUEST',
  FETCH_LIST_FINANCEIRO_ALUNO_SUCCESS: 'FETCH_LIST_FINANCEIRO_ALUNO_SUCCESS',
  FETCH_LIST_FILES_REQUEST: 'FETCH_LIST_FILES_REQUEST',
  FETCH_LIST_FILES_SUCCESS: 'FETCH_LIST_FILES_SUCCESS',
  REMOVER_PROCESSO_ALUNO_REQUEST: 'REMOVER_PROCESSO_ALUNO_REQUEST',
  REMOVER_PROCESSO_ALUNO_SUCCESS: 'REMOVER_PROCESSO_ALUNO_SUCCESS',
  DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  SET_FILTROS: 'SET_FILTROS',

  fetchListAlunos: (payload: any) => ({
    type: actions.FETCH_LIST_ALUNOS_REQUEST,
    payload,
  }),

  setIsLoading: (payload: any) => ({
    type: actions.SET_IS_LOADING_LIST,
    payload,
  }),

  insertAluno: (aluno: any, callback: () => void) => ({
    type: actions.INSERT_ALUNO_REQUEST,
    payload: aluno,
    callback: callback,
  }),

  patchAluno: (aluno: any, callback: () => void) => ({
    type: actions.PATCH_ALUNO_REQUEST,
    payload: aluno,
    callback: callback,
  }),

  fetchAluno: (alunoID: any) => ({
    type: actions.FETCH_ALUNO_REQUEST,
    payload: alunoID,
  }),

  deleteAluno: (aluno: any, params: any) => ({
    type: actions.DELETE_ALUNO_REQUEST,
    payload: aluno,
    params,
  }),

  inactiveAluno: (aluno: any, status: any, params: any) => ({
    type: actions.INACTIVE_ALUNO_REQUEST,
    payload: aluno,
    status: status,
    params: params,
  }),

  updateAluno: (aluno: any, callback: () => void) => ({
    type: actions.UPDATE_ALUNO_REQUEST,
    payload: aluno,
    callback,
  }),

  setAluno: (alunoID: any) => ({
    type: actions.SET_ALUNO_ID,
    payload: alunoID,
  }),

  fetchListFiles: (alunoID: any) => ({
    type: actions.FETCH_LIST_FILES_REQUEST,
    alunoID,
  }),

  reopenProcess: (vendaID: any, callback: () => void) => ({
    type: actions.REABRIR_PROCESSO_ALUNO_REQUEST,
    vendaID: vendaID,
    callback,
  }),

  fetchListFinanceiroAluno: (alunoID: any) => ({
    type: actions.FETCH_LIST_FINANCEIRO_ALUNO_REQUEST,
    alunoID,
  }),

  payInstallments: (payload: any, callback: () => void) => ({
    type: actions.PAY_INSTALLMENTS_REQUEST,
    payload,
    callback,
  }),

  dividerInstallments: (payload: any, callback: () => void) => ({
    type: actions.DIVIDER_INSTALLMENTS_REQUEST,
    payload,
    callback,
  }),

  reopenInstallments: (payload: any, callback: () => void) => ({
    type: actions.REOPEN_INSTALLMENTS_REQUEST,
    payload,
    callback,
  }),

  reopenInstallmentsStatement: (payload: any, callback: () => void) => ({
    type: actions.REOPEN_INSTALLMENTS_STATEMENT_REQUEST,
    payload,
    callback,
  }),

  fetchListCNHSAluno: (alunoID: any) => ({
    type: actions.FETCH_LIST_CNHS_REQUEST,
    alunoID,
  }),

  insertCNH: (payload: any, callback: () => void) => ({
    type: actions.INSERT_CNH_REQUEST,
    payload: payload,
    callback,
  }),

  updateCNH: (payload: any, idCNH: any, callback: () => void) => ({
    type: actions.UPDATE_CNH_REQUEST,
    payload: payload,
    idCNH: idCNH,
    callback,
  }),

  deleteCNH: (CNHid: any, callback: () => void) => ({
    type: actions.DELETE_CNH_REQUEST,
    payload: CNHid,
    callback,
  }),

  insertRenach: (payload: any, vendaID: any, callback: () => void, values: any) => ({
    type: actions.INSERT_RENACH_REQUEST,
    payload: payload,
    vendaID: vendaID,
    callback,
    values,
  }),

  deleteFile: (payload: any) => ({
    type: actions.DELETE_FILE_REQUEST,
    payload: payload,
  }),

  setFiltros: (payload: any) => ({
    type: actions.SET_FILTROS,
    payload,
  }),
};

export default actions;
